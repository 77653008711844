import { gql } from "@apollo/client";

export const GET_INFOS_BY_LABEL = gql`
  query infos($label: String, $locale: I18NLocaleCode) {
    infos(filters: { label: { eqi: $label } }, locale: $locale) {
      data {
        attributes {
          title
          info
        }
      }
    }
  }
`;
